/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from "react";
import { QRCode } from "react-qrcode-logo";
import { useParams } from "react-router-dom";

import { CardContext } from "../context/card-context";
import Footer from "../components/footer";

const QRPage = () => {
  const { id } = useParams();
  const { loadCard, loadedCard } = useContext(CardContext);

  useEffect(() => {
    loadCard(id);
  }, []);

  if (!loadedCard) {
    return (
      <div className="page">
        <h3>Card has been deleted</h3>
      </div>
    );
  }
  return (
    <div
      style={{
        background: `linear-gradient(360deg, rgba(62, 86, 99, 0.8) 0%, rgba(44, 66, 78, 0) 100%, rgba(44, 66, 78, 0) 100%), url("/images/blurry-background.svg")`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
      className="page"
    >
      {loadedCard.activeStatus === false ? (
        <div>
          <h3>Card has been deactivated</h3>
        </div>
      ) : (
        <>
          <div className="profile-image-container">
            <img
              src={`${
                loadedCard?.cardImage ? loadedCard?.cardImage : "/images/Be.svg"
              }`}
              alt={loadedCard?.name}
            />
            <h3>{loadedCard.name}</h3>
            <p>{loadedCard.title}</p>
          </div>
          <div className="glass-card">
            <div className="glass-card-content">
              {loadedCard?.shortName && (
                <QRCode
                  style={{ width: "100%", height: "90%" }}
                  value={`https://cards.sailspad.com/${loadedCard?.shortName}/`}
                  quietZone={10}
                  size={280}
                  fgColor="#377B9E"
                  bgColor="transparent"
                  qrStyle="dots"
                  eyeRadius={15}
                  ecLevel="H"
                  enableCORS={true}
                  logoWidth={50}
                  logoHeight={50}
                  logoImage="https://res.cloudinary.com/salihudev/image/upload/v1660242370/qrlogo_rps5tt.svg"
                  removeQrCodeBehindLogo={true}
                />
              )}
            </div>
          </div>
        </>
      )}
      <Footer />
    </div>
  );
};

export default QRPage;
