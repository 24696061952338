import React from "react";

const Footer = () => {
  return (
    <footer>
      <img
        src="https://res.cloudinary.com/salihudev/image/upload/v1655069307/Sailspad_fzszwb.svg"
        alt="sailspad logo"
      />
      <p>All rights reserved {new Date().getFullYear()}</p>
    </footer>
  );
};

export default Footer;
