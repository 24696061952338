/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useContext } from "react";
import { useParams } from "react-router-dom";

import { CardContext } from "../context/card-context";
import { iconSelector } from "../utils/iconSelector";

import Footer from "../components/footer";

const ProfilePage = () => {
  const { id } = useParams();
  const { loadCard, loadedCard } = useContext(CardContext);

  useEffect(() => {
    loadCard(id);
  }, []);

  if (!loadedCard) {
    return (
      <div className="page">
        <h3>Card has been deleted</h3>
      </div>
    );
  }

  return (
    <div
      style={{
        background: `linear-gradient(360deg, rgba(62, 86, 99, 0.8) 0%, rgba(44, 66, 78, 0) 100%, rgba(44, 66, 78, 0) 100%), url("/images/blurry-background.svg")`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
      className="page"
    >
      {loadedCard.activeStatus === false ? (
        <div>
          <h3>Card has been deactivated</h3>
        </div>
      ) : (
        <>
          <div className="profile-image-container">
            <img
              src={`${
                loadedCard?.cardImage ? loadedCard?.cardImage : "/images/Be.svg"
              }`}
              alt={loadedCard?.name}
            />
            <h3>{loadedCard.name}</h3>
            <p>{loadedCard.title}</p>
          </div>
          <div className="glass-card">
            <div className="glass-card-content">
              <h3>About</h3>
              <p>{loadedCard.about}</p>
              <h3>Connect on</h3>
              <div className="link-container">
                {loadedCard?.links?.map((link, i) => (
                  <a
                    key={i}
                    href={
                      link?.name === "phone"
                        ? "tel:" + link?.link
                        : link?.name === "email"
                        ? "mailto:" + link?.link
                        : link?.link
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    {iconSelector(link.name)}
                  </a>
                ))}
              </div>
              <p>or</p>
              <button
                className="link-button"
                onClick={() => {
                  window.location.assign(
                    `${process.env.REACT_APP_API_URL}/card/view-mobile?id=${id}`
                  );
                }}
              >
                <h3>Go to AR</h3>
              </button>
            </div>
          </div>
        </>
      )}
      <Footer />
    </div>
  );
};

export default ProfilePage;
