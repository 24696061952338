import {
  BsTwitter,
  BsInstagram,
  BsGithub,
  BsLinkedin,
  BsGlobe2,
  BsFillTelephoneFill,
  BsWhatsapp,
  BsEnvelope,
  BsBehance,
  BsTelegram,
  BsPinterest,
  BsReddit,
  BsDiscord,
  BsSnapchat,
  BsFacebook,
} from "react-icons/bs";
import { FaTiktok } from "react-icons/fa";

export const iconSelector = (icon) => {
  switch (icon) {
    case "twitter":
      return <BsTwitter size={25} />;
    case "instagram":
      return <BsInstagram size={25} />;
    case "github":
      return <BsGithub size={25} />;
    case "linkedin":
      return <BsLinkedin size={25} />;
    case "website":
      return <BsGlobe2 size={25} />;
    case "phone":
      return <BsFillTelephoneFill size={25} />;
    case "whatsapp":
      return <BsWhatsapp size={25} />;
    case "email":
      return <BsEnvelope size={25} />;
    case "tiktok":
      return <FaTiktok size={25} />;
    case "behance":
      return <BsBehance size={25} />;
    case "telegram":
      return <BsTelegram size={25} />;
    case "pinterest":
      return <BsPinterest size={25} />;
    case "reddit":
      return <BsReddit size={25} />;
    case "discord":
      return <BsDiscord size={25} />;
    case "snapchat":
      return <BsSnapchat size={25} />;
    case "facebook":
      return <BsFacebook size={25} />;
    default:
      return;
  }
};
