//External components
import { Routes, Route } from "react-router-dom";

//Pages imports
import ViewARPage from "./pages/ViewARPage";
import ViewARPageMobile from "./pages/ViewARPageMobile";
import ProfilePage from "./pages/ProfilePage";
import ProfilePageMobile from "./pages/ProfilePageMobile";
import QRPage from "./pages/QRPage";

function App() {
  return (
    <div>
      <Routes>
        <Route exact path="/card/:id/view" element={<ViewARPage />} />
        <Route
          exact
          path="/card/:id/view-mobile"
          element={<ViewARPageMobile />}
        />
        <Route exact path="/card/:id/profile" element={<ProfilePage />} />
        <Route
          exact
          path="/card/:id/profile-mobile"
          element={<ProfilePageMobile />}
        />
        <Route exact path="/card/:id/qr" element={<QRPage />} />
      </Routes>
    </div>
  );
}

export default App;
