import { createContext, useState } from "react";
import axios from "axios";
export const CardContext = createContext({
  loadedCard: null,
});

export const CardProvider = ({ children }) => {
  const [loadedCard, setLoadedCard] = useState({});

  const loadCard = async (id) => {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/card/${id}/details`
    );
    if (res.status === 200) {
      setLoadedCard(res.data);
    } else {
      setLoadedCard(null);
    }
  };

  return (
    <CardContext.Provider value={{ loadedCard, loadCard }}>
      {children}
    </CardContext.Provider>
  );
};
