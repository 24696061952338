/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CardContext } from "../context/card-context";

import Footer from "../components/footer";

const ViewARPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { loadCard, loadedCard } = useContext(CardContext);

  useEffect(() => {
    loadCard(id);
  }, []);

  if (!loadedCard) {
    return (
      <div
        className="page"
        style={{
          backgroundImage: `linear-gradient(360deg, rgba(62, 86, 99, 0.8) 0%, rgba(44, 66, 78, 0) 100%, rgba(44, 66, 78, 0) 100%), url('/images/blurry-background.svg')`,
        }}
      >
        <h3>Card has been deleted</h3>
      </div>
    );
  }

  return (
    <>
      <div
        style={{
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundImage: `linear-gradient(360deg, rgba(62, 86, 99, 0.8) 0%, rgba(44, 66, 78, 0) 100%, rgba(44, 66, 78, 0) 100%), url(${
            !loadedCard?.backgroundImage
              ? "/images/blurry-background"
              : loadedCard.backgroundImage
          })`,
        }}
        className="page"
      >
        {loadedCard.activeStatus === false ? (
          <div>
            <h3 style={{ color: loadedCard.backgroundImage && "white" }}>
              Card has been deactivated
            </h3>
          </div>
        ) : (
          <>
            <div className="image-container">
              <img
                src={`${
                  loadedCard.logoImage ? loadedCard.logoImage : "/images/Be.svg"
                }`}
                alt="logo"
              />
            </div>
            <div className="glass-card">
              <div className="glass-card-content">
                <h3>
                  To verify the card click start AR, then allow the camera to
                  access the card
                </h3>
                <p>
                  Make sure the card is on the marker side and it's
                  uninterrupted{" "}
                </p>
                {id !== undefined && (
                  <button
                    className="button"
                    onClick={() => {
                      window.location.assign(
                        `${process.env.REACT_APP_API_URL}/card/view?id=${id}`
                      );
                    }}
                  >
                    Start AR
                  </button>
                )}

                <p>or</p>

                {id !== undefined && (
                  <button
                    className="link-button"
                    onClick={() => navigate(`/card/${id}/profile`)}
                  >
                    Go to Profile
                  </button>
                )}
              </div>
            </div>
          </>
        )}
        <Footer />
      </div>
    </>
  );
};

export default ViewARPage;
